import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Img from 'gatsby-image';
import Banner from 'components/usability-testing/banner';
import arrow from 'img/ui/arrow_white.svg';

const WebsiteUserTesting = () => {
  const { pageviews, statsDollar, lsApp, survey } = useStaticQuery(graphql`
    query {
      pageviews: file(
        relativePath: { eq: "usability-testing/website-user-testing/pageviews.png" }
      ) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      statsDollar: file(
        relativePath: { eq: "usability-testing/website-user-testing/statsDollar.png" }
      ) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lsApp: file(relativePath: { eq: "usability-testing/website-user-testing/lsApp.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      survey: file(relativePath: { eq: "usability-testing/website-user-testing/survey.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <h2>What is website usability testing?</h2>
      <p>
        Usability testing is a process where a set of designated users test out the design and
        development of a website. The users are made to interact with the interface in various ways
        to assess how well they interact with the website, if there are any potential loopholes, and
        whether you made the right design decisions. Usability website testing gives you a glimpse
        into how the target audience might use your website in reality. Once you triage what the
        website is lacking, it becomes easy to fix any design, layout, or coding issues before your
        website goes live.
      </p>
      <h2>The benefits of website user testing</h2>
      <p>
        In this era of expansive data consumption, most visitors are looking for a unique and fast
        experience, and are not willing to spare time on a website that is not user-friendly.{' '}
        <Link
          href="https://www.section.io/blog/page-load-time-bounce-rate/#:~:text=As%20you%20can%20see%2C%20the,quite%20low%20%2D%20only%209.61%25."
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          32.3% of visitors
        </Link>{' '}
        bounce off the page if the load time exceeds 7 seconds.
      </p>
      <Img
        fluid={pageviews.childImageSharp.fluid}
        alt="statistics"
        title="Number of pages viewed by page speed"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.section.io/blog/page-load-time-bounce-rate/#:~:text=As%20you%20can%20see%2C%20the,quite%20low%20%2D%20only%209.61%25."
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Section.io
        </Link>
      </p>
      <p>
        The biggest benefit of website testing is to pre-emptively tackle any issues that might
        arise. Armed with an insight into your website’s potential and weaknesses, you can then
        provide a premium user-experience.
      </p>
      <p>Let us delve into the various benefits of testing a website for usability:</p>
      <h3>Improve the retention rate of your website</h3>
      <p>
        Having visitors is great but having long-term repeat visitors should be the goal of any
        successful website. The usability testing helps with understanding a website’s retention
        rate and the user behavior, which in turn helps in the overall website improvement.
      </p>
      <h3>Reduces development and support costs</h3>
      <p>
        Being able to identify bugs and issues beforehand gives you the ability to save on
        additional developmental costs. Once the website is live, making code or design changes will
        always cost extra - not to mention the potential customer complaints and downtimes that can
        further expand your costs. In fact, it costs up to{' '}
        <Link
          href="https://www.sidekickgh.com/why-website-user-experience-is-important-to-getting-more-sales/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          100 times more
        </Link>{' '}
        to fix a UX error after development than prior to it.
      </p>
      <h3>Understand the user behavior </h3>
      <p>
        By conducting usability testing, you can observe user behavior in real-time. Including the
        end-users in the decision-making process, helps you empathize with their needs and come up
        with a better UX design that caters to the real needs of the users and not only to the
        perceived ones.
      </p>
      <h3>Identify lacking aspects of your website </h3>
      <p>
        Even carefully crafted websites are bound to have issues. Problems like broken links,
        website errors, content issues, and layout discrepancies can create setbacks. Website{' '}
        <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
          usability testing
        </Link>{' '}
        can help pinpoint the unresponsive areas of the website.
      </p>
      <h3>Provide a better user experience and increase sales</h3>
      <p>
        It’s a no-brainer that a happy customer is a repeat customer. By conducting website user
        testing, you can keep the users engaged by providing a great user experience and increase
        sales in the process. In fact, according to a{' '}
        <Link
          href="https://s3.amazonaws.com/coach-courses-us/public/theuxschool/uploads/The_Trillion_Dollar_UX_Problem.pdf?submissionGuid=23b51043-4050-4f24-a172-356d14650a19"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Career Foundry
        </Link>{' '}
        report, over $1.4 trillion is lost yearly by e-commerce companies due to bad UX.
      </p>
      <Img
        fluid={statsDollar.childImageSharp.fluid}
        alt="statistics"
        title="A trillion dollar issue in e-commerce"
      />
      <p>
        Source:{' '}
        <Link
          href="https://s3.amazonaws.com/coach-courses-us/public/theuxschool/uploads/The_Trillion_Dollar_UX_Problem.pdf?submissionGuid=23b51043-4050-4f24-a172-356d14650a19"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          The Trillion Dollar UX Problem
        </Link>{' '}
        by Career Foundry
      </p>
      <h2>A step-by-step approach to website usability testing</h2>
      <p>
        If you are looking for high conversion rates then conducting functionality tests is a must.
        Let us dig deeper into the various steps involved in this process:
      </p>
      <h3>Step 1: Plan for the test</h3>
      <p>
        According to the{' '}
        <Link
          href="https://www.usability.gov/how-to-and-tools/methods/usability-evaluation/index.html"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Usability.gov’s guidelines
        </Link>
        , the following steps should be used to plan for the test:
      </p>
      <p>
        <strong>Scope of Testing:</strong> Before the testing phase begins, it is important to
        decide what aspects of the website will be tested. The different testing areas might include
        the entire website, parts of it, certain apps or widgets, only the content, navigation
        structure, or a combination of each.
      </p>
      <p>
        <strong>Purpose: </strong>Identifying the goals and reasons for testing is imperative. Begin
        by preparing test scenarios. Is it easy for users to navigate from one page to another? Can
        they find specific information on certain products or topics? Is the shopping cart
        experience seamless for the end-users?
      </p>
      <p>
        <strong>Schedule and location: </strong>It is important to decide whether to conduct the
        usability website testing remotely or in-person.
      </p>
      <p>
        <strong>Participants: </strong>A key step is<strong> </strong>deciding<strong> </strong>on
        <strong> </strong>the number and type of participants. For a global presence, you would need
        to test the usability of your website based on certain demographics.
      </p>
      <p>
        <strong>Scenarios: </strong>You should come up with a number and list of questions and
        scenarios to hand out to your participants.<strong> </strong>Things like finding a certain
        product, filling out a form or ordering a product, etc. are the types of questions that can
        be listed on the tests.
      </p>
      <p>How about recruiting your testers?</p>
      <p>
        It is always best to match test participants with your potential target audience. If the
        selected participants understand the scope of your products and offerings, the tests will
        end up being way more productive.
      </p>
      <h3>Step 2: Run the test</h3>
      <p>There are two major website testing models. Let us divulge into both:</p>
      <p>
        <strong>Unmoderated remote testing and its benefits: </strong>Unmoderated remote testing is
        similar to traditional forms of testing, except for one key difference – the organizer and
        the test participants are both in geographically dispersed locations. Even though in-person
        testing is recommended by experts, there are tons of benefits with remote testing:
      </p>
      <ul>
        <li>
          It is great for businesses that are on a tight budget. No physical equipment or
          procurement of a facility is required to conduct testing.{' '}
        </li>
        <li>
          It is the perfect option when users are in different geographical locations. Due to no
          travel requirements, cost reductions are observed.{' '}
        </li>
        <li>
          A favorable option if you have time constraints. Since testing can be done remotely,
          people from different time zones can be recruited conveniently.
        </li>
      </ul>
      <p>
        <strong>Moderated or over-the-shoulder testing and its benefits: </strong>This is a
        traditional approach of usability testing where both the facilitator and the participant
        might be in the same room. The participants are given a bunch of tasks to perform and their
        feedback and expressions are observed. Here are some benefits of moderated testing:
      </p>
      <ul>
        <li>You have the power to observe the users and their expressions. </li>
        <li>
          It is easier to reach task clarity as the participants can ask for guidance on the spot.{' '}
        </li>
        <li>
          You can receive instant feedback and ask the participants to perform extra tasks if
          needed.
        </li>
      </ul>
      <h3>Step 3: Analyze your results</h3>
      <p>
        Once the tests have been conducted, the following steps should help you in analyzing the
        results:
      </p>
      <ol>
        <li>Collect all the data and jot down the main facts.</li>
        <li>
          Flag the issues that were observed into common areas for example “Login issues”, “Issues
          with the E-Commerce platform”, “About Us page”, or “Other”.{' '}
        </li>
        <li>
          Add similar issues under the same umbrella. For e.g., if you notice that three users were
          incapable of paying via PayPal, it would be wise to log all these issues under the
          “Payment Process” group.{' '}
        </li>
        <li>Make a list of all issues and prioritize them based on their business or UX value.</li>
      </ol>
      <Banner />
      <h2>Tips for website user testing</h2>
      <p>
        <strong>The number of participants:</strong> The godfather of website usability,{' '}
        <Link
          href="https://www.nngroup.com/articles/why-you-only-need-to-test-with-5-users/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Jakob Nielsen
        </Link>
        , recommends not using more than five participants to conduct website testing. Any users
        added after the first five will essentially run into similar problems repeatedly. This will
        only waste time and effort.
      </p>
      <p>
        <strong>Do not jump into conclusions: </strong>Unless<strong> </strong>you observe a clear
        pattern of similar issues, it is not wise to start fixing everything from the ground up. All
        users have varying skills and styles which need to be considered before implementing drastic
        changes. For instance, if users <em>a</em> and <em>b</em> are having a problem navigating to
        certain pages, it might not be true for the rest of the users.
      </p>
      <p>
        <strong>Record your testing session: </strong>Always record your sessions to keep track of
        the entire process. Invest in a web analytics software with session replay capabilities like{' '}
        <Link href="/" rel="noopener noreferrer" target="_blank">
          LiveSession
        </Link>
        .
      </p>
      <Img
        fluid={lsApp.childImageSharp.fluid}
        alt="Recorded sessions in LiveSession"
        title="LiveSession dashboard"
      />
      <p>
        Source:{' '}
        <Link href="/blog/engagement-score/" rel="noopener noreferrer" target="_blank">
          LiveSession
        </Link>
      </p>
      <p>
        <strong>Test early and test often: </strong>Waiting too long to test or not testing enough
        can have consequences. Testing early on in smaller chunks saves you from the costs of
        implementing hefty changes down the road.
      </p>
      <p>
        <strong>Run a website usability survey: </strong>Running<strong> </strong>a usability survey
        can help you achieve user satisfaction. The company{' '}
        <Link href="https://survicate.com/" rel="noopener noreferrer nofollow" target="_blank">
          Survicate
        </Link>{' '}
        provides a great website user testing survey{' '}
        <Link
          href="https://survicate.com/surveys/templates/website-usability-survey/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          template
        </Link>{' '}
        that you can use.
      </p>
      <Img fluid={survey.childImageSharp.fluid} alt="survey" title="Usability survey" />
      <p>
        Source:{' '}
        <Link
          href="https://survicate.com/surveys/templates/website-usability-survey/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Survicate
        </Link>
      </p>
      <h2>The key to effective usability testing of websites </h2>
      <p>
        There is nothing worse than having an online presence that does not speak well to the
        end-users. Fortunately, with website user testing, you can tweak and optimize even the
        weakest of websites before they go live. Here are the key findings that were discussed in
        this article:
      </p>
      <ul>
        <li>
          Website usability testing is a way to evaluate how well the users interact with your
          website.{' '}
        </li>
        <li>
          The main benefits of website user testing are improved retention rates, reduced
          development costs, and identification of the weak aspects of your website to name a few.{' '}
        </li>
        <li>
          A step-by-step approach to testing which includes planning, understanding the different
          testing models and analyzing the results.{' '}
        </li>
        <li>
          Tips and tricks on how to improve testing including the number of participants, not
          jumping into conclusions, recording your testing sessions, testing early and often, and
          conducting website usability surveys.
        </li>
      </ul>
      <p>Now it’s time to put theory into practice – good luck with your website user testing!</p>
      <div className="next-chapter">
        <Link href="/usability-testing/mobile-usability-test/">
          <div>
            <p>Chapter 6</p>
            <p>What is a mobile usability test and do you need it?</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(WebsiteUserTesting);
